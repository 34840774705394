body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom:30px;
}

p {
  margin:0
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: 0px0px
  }
  100%{
      background-position: -100% 0px
  }
}

.modalBackground {
  background-color:white;
  opacity:0.5;
  position: absolute;
  width:100%;
  height:100vh;
}

.placeholderGradientAnimation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, transparent 70%,rgba(255,255,255,0.3) 80%, transparent 100%);
  background-size: 2000px 104px;
  /* height: 100px; */
  position: relative;
}

.header {
  position:sticky;
  top:0;
  height:60px;
  background-color: white;
  z-index: 1000;

}

.header-grid {
  max-width:1190px;
  padding:0px 20px;
  box-sizing:border-box;
  display:grid;
  gap:30px;
  /* grid-template-columns:1fr 600px 1fr; */
  grid-template-columns:1fr min(600px, 60%) 1fr;
  margin:auto;
  align-items:center;
  height:100%;

}

.header-text {
  justify-self: start;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

input:focus-visible {
  outline:none;
}
.searchBar {
  all:unset;
}
.searchBar-results-container{
  /* position: absolute; */
  /* top: 55px; */
  /* width: 100%; */
  margin-top: 10px;
}

.pageContent {
  width:100%;
  max-width: 1190px;
  padding:0px 20px;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  row-gap: 60px;
}


.homepage-container {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*create a css variable*/
  --watchlistTilesPerRow: 4;
}

.homepageHero-container {
  width:100%;
  height:300px;
  background-color:#efefef;
  display:flex;
  justify-content:center;
}

.homepageHero-containerContent {
  align-items:center;
  flex-direction:row;
  justify-content:space-between;
  column-gap:10px;
}

.homepageHero-imageContainer {
  width:100%;
  max-width:485px;
  height:100%;
  background-color:transparent;
  overflow-y:hidden;
  padding:0px 10px;
  margin-right:-10px;
}

.homepageHero-imageContent {
  display:flex;
  gap:20px;
  transform:translate(0px, calc((300px - 100%)/2));
}

.homepageHero-movieColumn {
  display:flex;
  flex-direction:column;
  gap:20px;
  width:100%;
}

.homepageHero-text {
  display: flex;
  flex-direction: column;
}

.homepageHero-title {
  font-size:70px;
  line-height:70px;
  margin:0;
}

.homepageHero-slogan {
  font-size: 20px;
}

.homepage-watchlistContainer {
  display:grid;
  gap:30px;
}

.movieTileContainer-container {
  display:grid;
  gap:30px;
}

.watchlistTile-create-container {
  transform:scale(1);
  transition: transform 150ms  cubic-bezier(0.29, 0.44, 0.17, 1.01);
}
.watchlistTile-create-container:hover {
  transform:scale(1.05);
}


.watchlistTile-container {
  width:100%;
  aspect-ratio: 26/15;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 100ms  cubic-bezier(0.29, 0.44, 0.17, 1.01);
}

.watchlistTile-container:hover {
  transform:scale(1.025);
  
}

.movieTile {
  transition: all 100ms  cubic-bezier(0.29, 0.44, 0.17, 1.01);
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0);
  border-radius:10px;
}

.movieTile:hover {
  transform:scale(1.025);
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1), 0px 5px 20px 0px rgba(0,0,0,0.1); */
}

.watchlistTile-content {
  height:100%;
  padding:10px;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}

.watchlistTile-emoji {
  width:50px;
  height:50px;
}

.watchlistTile-text {
  font-size: 20px;
  font-weight: 500;
}

.watchlistAddTile-textarea::placeholder {
  color:rgb(184, 184, 184);
}
.watchlistAddTile-textarea:focus {
  /* outline:1px rgb(184, 184, 184) solid; */
  outline:none;
}

.watchlistEditModal-section {
  display:flex;
  flex-direction: column;
  gap:5px;
}
.watchlistEditModal-section-text {
  color:#808080;
}

.moviePage-container {
  margin-top:10px;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moviePage-posterColumn {
  display:flex;
  flex-direction:column;
  gap:20px;
}

.moviePage-poster-container {
  display:flex;
  flex-direction: column;
  border-radius: 10px;
}
.moviePage-poster-image{
  width:100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.moviePage-contentColumn {
  display:flex;
  flex-direction:column;
  gap:20px;
}

.moviePage-titleTile {
  padding:20px;
  border-radius: 10px;
}

.moviePage-titleTile-title {
  font-size: 32px;
  margin:0px;
}

.moviePage-titleTile-info {
  display:flex;
  gap:20px;
  font-weight:500;
}
.moviePage-creatorTile {
  /* padding:'10px 20px', borderRadius:10, gap:20, display:'flex', fontWeight:500 */
  padding:10px 20px;
  border-radius:10px;
  gap:20px;
  display:flex;
  font-weight:500;
}
.moviePage-overviewTile {
  padding:10px 20px 20px 20px;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  gap:20px;
}
.moviePage-overviewTile-title{
  margin:0px;
  font-size:20px;
}

.watchlistAddModal-createButton {
  width:22px;
  height:22px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  /* background-color:#efefef; */
  border-radius:5px;
  /* box-shadow: 0px 0px 0px 3px #efefef; */
}

.watchlistAddModal-createButton:hover {
  background-color:#efefef;
  box-shadow: 0px 0px 0px 3px #efefef;
}

.watchlistPage {
  display:flex;
  flex-direction:column;
  gap:30px;
  align-items:center;
}

.watchlistPage-buttonRow {
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.watchlistPage-button {
  padding:5px 10px;
  display:flex;
  gap:5px;
  align-items:center;
  border-radius:5px;
  cursor:pointer;
}
.watchlistPage-sortButtonOption{
  background-color: white;
}
.watchlistPage-sortButtonOption:hover {
  background-color: #f8f8f8;
}

.watchlistPage-categoryButtons-master {
  display:grid;
  grid-template-columns:1fr 1fr;
  justify-items:center;
  gap:15px;
  position:absolute;
  left:50%;
  transform:translate(-50%,0);
  font-size:20px;
}


.watchlistPage-hero-master {
  height:300px;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}

.watchlistPage-hero-text {
  font-size:70px;
  line-height:70px;
}

.watchlistPage-hero-emoji {
  width:200px;
  height:200px;
}

.addMovieToWatchlist-positioner {
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.watchlistAddModal-master {
  background-color:white;
  border-radius:10px;
  margin:15px;
  box-shadow:0px 0px 15px rgba(0,0,0,0.25);
  width:400px;
}

.castTile-container {
  display:flex;
  border-radius:10px;
}

.castTile-image {
  border-radius:10px 0px 0px 10px;
  width:100%;
  height:100%;
  object-fit:cover;
  padding:1px;
  box-sizing:border-box;
}
.castTile-image-mobile {
  max-width:100%;
  max-height:100%;
  width:100%;
  object-fit:cover;
  border-radius:10px;
}

.castTile-image-placeholder {
  position:absolute;
  opacity:0.2;
  border-radius:10px 0px 0px 10px;
  top:0;
  bottom:0;
  display:flex;
  width:66px;
  align-items:center;
  justify-content:center;
}

.castTile-fadeDiv{
  position:absolute;
  border-radius:10px 0px 0px 10px;
  width:100%;
  height:100%;
}

.castTile-noImage {
  position:absolute;
  border-radius:10px 0px 0px 10px;
  top:0;
  bottom:0;
  display:flex;
  width:66px;
  align-items:center;
  justify-content:center;
}

.palceholderText {
  opacity:0.2;
  color:transparent;
  border-radius:8px;
  width:fit-content;
}



@media (max-width:1000px) {
  /* .homepageHero-title {
    font-size: 60px;
    line-height: 60px;
  } */

  .homepageHero-container {
    height: 400px
  }
  .homepageHero-containerContent {
    column-gap: 20px;
  }

  .homepageHero-imageContent {
    transform:translate(0px, calc((400px - 100%)/2));
    gap:10px;
  }
  .homepageHero-movieColumn {
    gap:10px;
  }

  .homepage-watchlistContainer {
    gap:15px;
  }
  .movieTileContainer-container {
    gap:15px;
  }

}


@media (max-width:500px) {
  .header-grid {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
  }
  .pageContent {
    padding: 0px 15px;
  }

  .homepageHero-containerContent {
    flex-direction:column;
    row-gap:40px;
  }

  .homepageHero-text {
    align-self: flex-start;
    margin-top:30px;
  }

  .homepageHero-title {
    font-size: 48px;
    line-height: 48px;
  }

  .homepageHero-slogan {
    font-size:16px
  }

  .homepageHero-imageContainer{
    padding:10px;
    margin-top:-10px;
    margin-right:0px;
  }

  .homepageHero-imageContent {
    transform:translate(0px,0px);
  }

  .homepage-watchlistContainer {
    gap:10px;
  }

  .watchlistTile-emoji {
    width:35px;
    height:35px;
  }

  .watchlistTile-text {
    font-size: 15px;
  }

  .movieTileContainer-container {
    gap:10px;
  }

  .moviePage-titleTile {
    padding:15px;
  }
  .moviePage-titleTile-title {
    font-size: 24px;
  }
  .moviePage-titleTile-info{
    font-size:15px;
  }
  .moviePage-poster-image{
    border-radius: 10px;
  }
  .moviePage-creatorTile{
    gap:10px;
    align-items: center;
    padding:10px;
    font-size: 15px;
    flex-direction: column;
    min-height: 35%;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .moviePage-overviewTile {
    padding:15px;
    gap:5px;
    font-size: 15px;
  }
  .moviePage-overviewTile-title{
    font-size:16px;
  }

  .watchlistPage-categoryButtons-master {
    position:initial;
    transform: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  .watchlistPage-hero-master {
    height:200px;
  }

  .watchlistPage-hero-text {
    font-size:25px;
    line-height:25px;
  }

  .watchlistPage-hero-emoji {
    width:75px;
    height:75px;
  }

  .watchlistPage {
    gap:15px;
  }

  .addMovieToWatchlist-positioner {
    width:100%;
  }

  .watchlistAddModal-master {
    background-color:white;
    border-radius:10px;
    margin:15px;
    box-shadow:0px 0px 15px rgba(0,0,0,0.25);
    width:auto;
  }


}